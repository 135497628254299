const errors = [
  { code: 400, description: 'Você pode ter digitado o endereço (URL) incorretamente.' },
  { code: 403, description: 'Talvez você não tenha autorização para acessar essa página.' },
  { code: 404, description: 'Não conseguimos encontrar a página solicitada.' },
  { code: 410, description: 'Não conseguimos encontrar a página solicitada.' },
  { code: 500, description: 'Estamos com problemas em nossos servidores, mas já estamos trabalhando para resolver.' },
  { code: 503, description: 'No momento, nossos servidores não conseguiram atender sua solicitação.' },
  { code: 504, description: 'O servidor não conseguiu responder a tempo.' },
]

const errorDescription = (code) => {
  const error = errors.find((item) => item.code === code)

  return error ? error.description : ''
}

export default errorDescription
