const STATIC_SEO = [
  {
    links: [
      {
        name: 'Apartamentos para vender em São Paulo',
        href: '/venda/apartamentos/sp+sao-paulo/',
      },
      {
        name: 'Venda em São Paulo de casas',
        href: '/venda/casas/sp+sao-paulo/',
      },
      {
        name: 'Quitinetes em São Paulo para alugar',
        href: '/aluguel/quitinetes/sp+sao-paulo/',
      },
      {
        name: 'Apartamentos à venda em Belo Horizonte',
        href: '/venda/apartamentos/mg+belo-horizonte/',
      },
      {
        name: 'Apartamentos para vender em Santos',
        href: '/venda/apartamentos/sp+santos/',
      },
      {
        name: 'Apartamentos à venda em Curitiba',
        href: '/venda/apartamentos/pr+curitiba/',
      },
      {
        name: 'Apartamentos para alugar em Curitiba',
        href: '/venda/apartamentos/pr+curitiba/',
      },
      {
        name: 'Venda apartamentos em Porto Alegre',
        href: '/venda/apartamentos/rs+porto-alegre/',
      },
      {
        name: 'Apartamentos à venda em Rio de Janeiro',
        href: '/venda/apartamentos/rj+rio-de-janeiro/',
      },
      {
        name: 'Apartamentos para alugar em Santos',
        href: '/aluguel/apartamentos/sp+santos/',
      },
      {
        name: 'Apartamentos para vender em Praia Grande',
        href: '/venda/apartamentos/sp+praia-grande/',
      },
      {
        name: 'Apartamentos para alugar em Rio de Janeiro',
        href: '/aluguel/apartamentos/rj+rio-de-janeiro/',
      },
      {
        name: 'Venda apartamentos em Recreio dos Bandeirantes',
        href: '/venda/apartamentos/rj+rio-de-janeiro+zona-oeste+recreio-dos-bandeirantes/',
      },
      {
        name: 'Apartamentos para alugar em Recreio dos Bandeirantes',
        href: '/aluguel/apartamentos/rj+rio-de-janeiro+zona-oeste+recreio-dos-bandeirantes/',
      },
      {
        name: 'Venda apartamentos em Barra da Tijuca',
        href: '/venda/apartamentos/rj+rio-de-janeiro+zona-oeste+barra-da-tijuca/',
      },
    ],
    header: {
      name: 'Buscas mais populares',
      rel: '',
    },
    category: 'HOME_STATIC',
  },
  {
    links: [
      {
        name: 'Apartamentos à venda em Fortaleza',
        href: '/venda/apartamentos/ce+fortaleza/',
      },
      {
        name: 'Apartamentos para vender em São Bernardo do Campo',
        href: '/venda/apartamentos/sp+sao-bernardo-do-campo/',
      },
      {
        name: 'Apartamentos para alugar em São Paulo',
        href: '/aluguel/apartamentos/sp+sao-paulo/mobiliado/',
      },
      {
        name: 'Apartamentos para alugar em Porto Alegre',
        href: '/aluguel/apartamentos/rs+porto-alegre/direto-com-proprietario/',
      },
      {
        name: 'Apartamentos à venda em Recife',
        href: '/venda/apartamentos/pe+recife/',
      },
      {
        name: 'Venda apartamentos em Copacabana',
        href: '/venda/apartamentos/rj+rio-de-janeiro+zona-sul+copacabana/',
      },
      {
        name: 'Apartamentos para vender em Jundiaí',
        href: '/venda/apartamentos/sp+jundiai/',
      },
      {
        name: 'Apartamentos para alugar em Salvador',
        href: '/aluguel/apartamentos/ba+salvador/',
      },
      {
        name: 'Apartamentos para alugar em Fortaleza',
        href: '/aluguel/apartamentos/ce+fortaleza/',
      },
      {
        name: 'Apartamentos para alugar em Balneario Camboriú',
        href: '/aluguel/apartamentos/sc+balneario-camboriu/',
      },
      {
        name: 'Venda em casas Guarulhos',
        href: '/venda/casas/sp+guarulhos/',
      },
      {
        name: 'Apartamentos para alugar em Florianópolis',
        href: '/aluguel/apartamentos/sc+florianopolis/',
      },
      {
        name: 'Casas em Florianópolis para vender',
        href: '/venda/casas/sc+florianopolis/',
      },
      {
        name: 'Alugar apartamentos em Pinheiros',
        href: '/aluguel/apartamentos/sp+sao-paulo+zona-oeste+pinheiros/',
      },
      {
        name: 'Apartamentos para vender em Santo André',
        href: '/venda/apartamentos/sp+santo-andre/',
      },
    ],
    header: {
      name: '',
      rel: '',
    },
    category: 'HOME_STATIC',
  },
  {
    links: [
      {
        name: 'Apartamentos à venda em Salvador',
        href: '/venda/apartamentos/ba+salvador/',
      },
      {
        name: 'Quitinetes em Belo Horizonte para alugar',
        href: '/aluguel/quitinetes/mg+belo-horizonte/',
      },
      {
        name: 'Apartamentos para alugar em Barra da Tijuca',
        href: '/aluguel/apartamentos/rj+rio-de-janeiro+zona-oeste+barra-da-tijuca/',
      },
      {
        name: 'Apartamentos à venda em Goiania',
        href: '/venda/apartamentos/go+goiania/',
      },
      {
        name: 'Apartamentos para vender em Guarulhos',
        href: '/venda/apartamentos/sp+guarulhos/',
      },
      {
        name: 'Quitinetes em Rio de Janeiro para alugar',
        href: '/aluguel/quitinetes/rj+rio-de-janeiro/',
      },
      {
        name: 'Alugar casas em Curitiba',
        href: '/aluguel/casas/pr+curitiba/',
      },
      {
        name: 'Lançamentos de apartamentos em São Paulo',
        href: '/lancamentos/apartamentos/sp+sao-paulo/',
      },
      {
        name: 'Venda apartamentos em Guaruja',
        href: '/venda/apartamentos/sp+guaruja/',
      },
      {
        name: 'Apartamentos para alugar em Goiania',
        href: '/aluguel/apartamentos/go+goiania/',
      },
      {
        name: 'Venda apartamentos em Florianopolis',
        href: '/venda/apartamentos/sc+florianopolis/',
      },
      {
        name: 'Apartamentos para alugar em Tijuca',
        href: '/aluguel/apartamentos/rj+rio-de-janeiro+zona-norte+tijuca/',
      },
      {
        name: 'Casas de condominio em São Paulo à venda',
        href: '/venda/casas-de-condominio/sp+sao-paulo/',
      },
      {
        name: 'Alugar flat em São Paulo',
        href: '/aluguel/flat/sp+sao-paulo/',
      },
      {
        name: 'Casas em Curitiba para vender',
        href: '/venda/casas/pr+curitiba/',
      },
    ],
    header: {
      name: '',
      rel: '',
    },
    category: 'HOME_STATIC',
  },
]

export default STATIC_SEO
