import locationImages from '@/utils/constants/location-images'

const {
  SP,
  RJ,
  MG,
  RS,
  PR,
} = locationImages

export const LOCATIONS = [
  {
    links: [
      {
        name: 'São Paulo',
        href: 'imoveis/sp+sao-paulo/',
      },
      {
        name: 'Campinas',
        href: 'imoveis/sp+campinas/',
      },
      {
        name: 'São Bernardo do Campo',
        href: 'imoveis/sp+sao-bernardo-do-campo/',
      },
      {
        name: 'Santo André',
        href: 'imoveis/sp+santo-andre/',
      },
      {
        name: 'Sao Jose dos Campos',
        href: 'imoveis/sp+sao-jose-dos-campos/',
      },
    ],
    header: {
      name: 'São Paulo',
      image: SP,
      href: 'imoveis/sp/',
    },
  },
  {
    links: [
      {
        name: 'Rio de Janeiro',
        href: 'imoveis/rj+rio-de-janeiro/',
      },
      {
        name: 'Niterói',
        href: 'imoveis/rj+niteroi/',
      },
      {
        name: 'São Gonçalo',
        href: 'imoveis/rj+sao-goncalo/',
      },
      {
        name: 'Duque de Caxias',
        href: 'imoveis/rj+duque-de-caxias/',
      },
      {
        name: 'Nova Iguaçu',
        href: 'imoveis/rj+nova-iguacu/',
      },
    ],
    header: {
      name: 'Rio de Janeiro',
      image: RJ,
      href: 'imoveis/rj/',
    },
  },
  {
    links: [
      {
        name: 'Belo Horizonte',
        href: 'imoveis/mg+belo-horizonte/',
      },
      {
        name: 'Uberlândia',
        href: 'imoveis/mg+uberlandia/',
      },
      {
        name: 'Contagem',
        href: 'imoveis/mg+contagem/',
      },
      {
        name: 'Juiz de Fora',
        href: 'imoveis/mg+juiz-de-fora/',
      },
      {
        name: 'Betim',
        href: 'imoveis/mg+betim/',
      },
    ],
    header: {
      name: 'Minas Gerais',
      image: MG,
      href: 'imoveis/mg/',
    },
  },
  {
    links: [
      {
        name: 'Porto Alegre',
        href: 'imoveis/rs+porto-alegre/',
      },
      {
        name: 'Canoas',
        href: 'imoveis/rs+canoas/',
      },
      {
        name: 'Caxias do Sul',
        href: 'imoveis/rs+caxias-do-sul/',
      },
      {
        name: 'Pelotas',
        href: 'imoveis/rs+pelotas/',
      },
      {
        name: 'Novo Hamburgo',
        href: 'imoveis/rs+novo-hamburgo/',
      },
    ],
    header: {
      name: 'Rio Grande do Sul',
      image: RS,
      href: 'imoveis/rs/',
    },
  },
  {
    links: [
      {
        name: 'Curitiba',
        href: 'imoveis/pr+curitiba/',
      },
      {
        name: 'Londrina',
        href: 'imoveis/pr+londrina/',
      },
      {
        name: 'Maringá',
        href: 'imoveis/pr+maringa/',
      },
      {
        name: 'São José dos Pinhais',
        href: 'imoveis/pr+sao-jose-dos-pinhais/',
      },
      {
        name: 'Foz do Iguaçu',
        href: 'imoveis/pr+foz-do-iguacu/',
      },
    ],
    header: {
      name: 'Paraná',
      image: PR,
      href: 'imoveis/pr/',
    },
  },
]

export const IMG_SIZES = Object.freeze({
  mobile: { height: 200 },
  tablet: { height: 200 },
  desktop: { height: 220 },
})
