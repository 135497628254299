import { mapGetters } from 'vuex'

import tracker from '@/components/seo/oz-seo-widgets-group/oz-seo-widgets-group.tracker'
import ozCarousel from '@/components/oz-carousel'

import { LOCATIONS, IMG_SIZES } from './constants'

export default {
  name: 'oz-main-locations',

  props: {
    businessType: {
      type: String,
      default: 'SALE',
    },
  },

  data () {
    return {
      LOCATIONS,
      spacing: 12,
      slidesPerView: 1,
      breakpoints: {
        '(min-width: 768px)': {
          slidesPerView: 2,
        },
        '(min-width: 1024px)': {
          slidesPerView: 2,
          spacing: 24,
        },
      },
    }
  },

  computed: {
    ...mapGetters(['currentPlatform']),

    imgSize () {
      return IMG_SIZES[this.currentPlatform]
    },
  },

  methods: {
    tracking (link) {
      const { name } = link
      tracker.linkClicked('MAIN_LOCATION', name)
    },
  },

  filters: {
    page (value, businessType) {
      return businessType === 'SALE' ? `/venda/${value}` : `/aluguel/${value}`
    },
  },

  components: {
    ozCarousel,
  },
}
