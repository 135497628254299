import errorDescription from '@/utils/http-status-code'
import ozMainLocations from '@/components/seo/oz-main-locations-poc'
import ozSeoWidgetsLinks from '@/components/seo/oz-seo-widgets-links'
import STATIC_SEO from './constants'

export default {
  name: 'http-error',

  props: {
    message: {
      type: String,
      default: 'Código de erro não identificado.',
    },
    statusCode: { type: Number, default: 410 },
  },

  data () {
    return {
      widgets: STATIC_SEO,
    }
  },

  created () {
    if (this.$ssrContext) {
      this.$ssrContext.HTTPStatus = this.statusCode
    }
  },
  computed: {
    statusHashCode () {
      return parseInt(this.$route.hash.replace('#', ''), 10) || 0
    },
    errorCode () {
      return this.statusHashCode || this.statusCode
    },
    subtitle () {
      if (this.errorCode >= 400 && this.errorCode < 500) {
        return 'Essa página não mora mais aqui!'
      }
      return 'Algo deu errado :('
    },
    contactLink () {
      return (this.errorCode >= 300 && this.errorCode < 400) || this.errorCode >= 500
    },
    textLinks () {
      if (this.contactLink) {
        return 'Separamos alguns links que podem te interessar! Dá uma olhadinha...'
      }
      return 'Mas separamos alguns links que podem te interessar! Dá uma olhadinha...'
    },
    description () {
      return errorDescription(this.errorCode) || this.message
    },
  },

  components: {
    ozMainLocations,
    ozSeoWidgetsLinks,
  },

}
